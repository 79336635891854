<template>
  <div>
    <inputText
      :value="password"
      :label="$t('private.password.title')"
      :callback="setPassword"
      :data="password"
      :help="$t('private.password.info')"
      :styling="{ input: 'width: 500px; max-width: 100%; display: block' }"
    />
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      password: JSON.parse(
        JSON.stringify(this.$store.state.privateEvent.unsaved)
      ).password
    };
  },
  computed: {},
  mounted() {},
  methods: {
    setPassword(val) {
      this.password = val;
      this.setSave();
    },
    setSave() {
      var data = JSON.parse(
        JSON.stringify(this.$store.state.privateEvent.unsaved)
      );
      data.password = this.password;
      this.$store.commit("privateEvent/prefetch", data);
    }
  }
};
</script>
<style lang="sass" scoped></style>
